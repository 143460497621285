<template>
  <div class="bank-id-fail">
    <Container>
      <Card padding="large" variant="border">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <CloseSVG class="icon h1 text-error" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('SOMETHING_WRONG') }}</h1>
              <p class="text-small">{{ $t('ORDER_FAILED_INFO') }}</p>

              <Separator size="large" />
              <b-row align-h="center" class="margins__double">
                <b-col cols="auto">
                  <Button @click="signWithBankId" :loading="isLoading">{{
                    $t('TRY_AGAIN')
                  }}</Button>
                </b-col>
                <b-col cols="auto">
                  <Button
                    :disabled="isLoading"
                    :to="{ name: ROUTES.LOAN_APPLICATION.name }"
                  >
                    {{ $t('GO_TO_LOAN_APPLICATION') }}
                  </Button>
                </b-col>
              </b-row>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SIGN_WITH_BANK_ID } from '@/types';
import { Container, Card, Margins, Button, Separator } from '@/components';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { constants } from '@/mixins';

export default {
  name: 'Fail',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Separator,
    CloseSVG,
  },
  methods: {
    ...mapActions([SIGN_WITH_BANK_ID]),
    signWithBankId() {
      this.SIGN_WITH_BANK_ID();
    },
  },
  computed: {
    ...mapState(['isLoading']),
  },
};
</script>
